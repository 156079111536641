<template>
  <div class="page-bg">
    <header class="header">
      <div class="header-logo">
        <div class="logo">
          <RouterLink to="/login">
            <img class="logo-lg" src="@/assets/images/logo.svg" alt="" />
            <img class="logo-sm" src="@/assets/images/logo-mobile.svg" alt="" />
          </RouterLink>
        </div>
      </div>
    </header>
    <div class="container">
      <main class="content">
        <div class="wrap-sm">
          <div class="page-title">
            您所瀏覽的網頁不存在
            <div class="h4">THE RESOURCE CANNOT BE FOUND</div>
          </div>

          <div class="pageError">
            <p>您要尋找的資源可能已經移除、名稱已經變更或是暫時無法使用，請重新點選網站連結。</p>
            <p>
              The resource you are looking for could have been removed, had its name changed, or is
              temporarily unavailable. Please check if the URL is correct, or click the link to find
              what you need.
            </p>
            <img class="pageError-image" src="@/assets/images/error.svg" alt="" />
            <RouterLink to="/" class="btn btn-md btn-primary">回系統首頁 | HOME</RouterLink>
          </div>
        </div>
      </main>
    </div>
    <footer class="footer">
      <div class="footer-wrapper">
        <div class="footer-left">
          <a
            href="https://tmc.taipei/"
            target="_blank"
            title="回官網(另開視窗)"
            class="footer-logo"
          >
            <img src="@/assets/images/logo-white.svg" alt="" />
          </a>
        </div>
        <div class="footer-right">
          <p>Copyright © Taipei music center all rights reserved ｜ 臺北流行音樂中心 版權所有</p>
          <p>115028 台北市南港區市民大道8段99號 +886-02-2788-6620 info@tmc.taipei</p>
        </div>
      </div>
    </footer>
  </div>
</template>
